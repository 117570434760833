import "./src/assets/styles/fonts.css"
import "./src/assets/styles/main.scss"
import sal from "sal.js"
import lozad from "lozad"


export const onRouteUpdate = ({ location }) => {
  const observer = lozad(".lazy", {
    rootMargin: "50px 0px",
    threshold: 0.01,
    loaded(el) {
      el.onload = () => {
        el.classList.add("imageFade")
      }
    },
  })
  observer.observe()

  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`).offsetTop

    window.scrollTo({
      top: item,
      behavior: "auto",
    })
  }

  sal()


  return true
}
