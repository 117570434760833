// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archives-archive-post-js": () => import("./../../../src/templates/archives/archive-post.js" /* webpackChunkName: "component---src-templates-archives-archive-post-js" */),
  "component---src-templates-archives-archive-project-js": () => import("./../../../src/templates/archives/archive-project.js" /* webpackChunkName: "component---src-templates-archives-archive-project-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/FrontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-post-types-post-js": () => import("./../../../src/templates/post-types/post.js" /* webpackChunkName: "component---src-templates-post-types-post-js" */),
  "component---src-templates-post-types-project-js": () => import("./../../../src/templates/post-types/project.js" /* webpackChunkName: "component---src-templates-post-types-project-js" */),
  "component---src-templates-types-about-services-page-template-js": () => import("./../../../src/templates/types/AboutServicesPageTemplate.js" /* webpackChunkName: "component---src-templates-types-about-services-page-template-js" */),
  "component---src-templates-types-contact-page-template-js": () => import("./../../../src/templates/types/ContactPageTemplate.js" /* webpackChunkName: "component---src-templates-types-contact-page-template-js" */),
  "component---src-templates-types-services-page-template-js": () => import("./../../../src/templates/types/ServicesPageTemplate.js" /* webpackChunkName: "component---src-templates-types-services-page-template-js" */)
}

